.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#goods-reserve .sort-box {
  margin-left: 0.24rem;
  color: #555;
  font-size: 0.28rem;
  white-space: nowrap;
  border-bottom: 1px solid #e4e4e4;
}
#goods-reserve .sort-box select {
  height: 0.9rem;
  border: none;
  color: #a9a9a9;
  font-size: 0.28rem;
  padding-left: 0.26rem;
  width: 40%;
}
#goods-reserve .yd-cell-right select {
  padding-left: 0.3rem;
  max-width: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#goods-reserve .goods-reserve-title {
  font-size: 0.24rem;
  padding: 0.26rem 0.26rem 0.16rem;
  color: #a9a9a9;
  background: #f5f5f5;
}
#goods-reserve .add {
  background: #fff;
  color: #00b7ee;
  font-size: 0.32rem;
  padding: 0.26rem 0;
  text-align: center;
}
#goods-reserve .to-delete {
  text-align: center;
  padding: 0.26rem 0;
  color: red;
}
#goods-reserve .perch {
  height: 3rem;
  width: 100%;
  background: #f5f5f5;
}
#goods-reserve .commit-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}
#goods-reserve .commit-box .go-commit,
#goods-reserve .commit-box .go-commits {
  width: 90%;
  padding: 0.26rem 0;
  background: #409eff;
  border-radius: 0.36rem;
  font-size: 0.36rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin: auto;
}
#goods-reserve .commit-box .go-commits {
  background: #ccc;
}
